import dayjs, { Dayjs } from 'dayjs'
import { CalendarViewType } from '../schemas'

export const getStartDateByView = (view: CalendarViewType, date: Dayjs) => {
  switch (view) {
    case 'month':
      return date.startOf('month')
    case 'week':
      return date.startOf('week')
    case 'three_days':
      return date.subtract(1, 'day')
  }
}

export const getEndDateByView = (view: CalendarViewType, date: Dayjs) => {
  switch (view) {
    case 'month':
      return date.endOf('month')
    case 'week':
      return date.endOf('week')
    case 'three_days':
      return date.add(1, 'day')
  }
}

export const getDateRangeByView = (view: CalendarViewType, date: Dayjs): Dayjs[] => {
  const start = getStartDateByView(view, date)
  const end = getEndDateByView(view, date)
  return dayjs.range(start, end, 'day')
}

export function getResourceType(path: string): string | undefined {
  const re = /\/m2\/([1-9]*)\/schedule\/(?<resourceType>[a-z]*)(.*)/
  const reMatch = path.match(re)
  const resourceType = reMatch?.groups?.resourceType

  return resourceType
}
