import { useIsMobile } from '@/hooks'
import { useCustomFormsStatus } from '@/modules/custom-form/hooks'
import { useJobRoles } from '@/modules/jas/job-manager/hooks'
import { getDateRangeByView } from '@/modules/jas/schedule/helpers/index'
import { CalendarViewType } from '@/modules/jas/schedule/schemas'
import { useCurrentModuleQuery } from '@/modules/module/hooks'
import { useTicketsParam } from '@/modules/ticket/list/hooks'
import { calendarEventsApi, ticketApiV2 } from '@/services/api-service'
import { Spin } from '@/ui/spin'
import { zodQueryFields } from '@/utils/zod'
import { useQuery } from '@tanstack/react-query'
import { Calendar } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { groupBy } from 'lodash'
import { FC, useMemo } from 'react'
import { transformTicket } from '../../helpers'
import { CalendarNote, CalendarNoteSchema, CalendarTicket, CalendarTicketSchema } from '../../schemas'
import { CalendarDayCell } from './calendar-day-cell'
import { MobileDayCalendar } from './mobile-day-calendar'

export const TicketsCalendar: FC = () => {
  // config
  const isMobile = useIsMobile()
  const { module } = useCurrentModuleQuery()
  const calendarConfig = module?.data?.calendar

  // filter params
  const { params: ticketsParams, ticketFilter } = useTicketsParam()
  const { filterService } = ticketFilter
  const view: CalendarViewType = useMemo(() => (isMobile ? 'week' : filterService.state.view || 'month'), [isMobile])
  const refDateStr = filterService.filter.ref_date

  const { refDate, startDate, endDate, dates } = useMemo((): {
    refDate: Dayjs
    startDate: Dayjs
    endDate: Dayjs
    dates: Dayjs[]
  } => {
    const refDate = dayjs.parse(refDateStr) || dayjs.now()
    const dates = getDateRangeByView(view, refDate)
    return { refDate: refDate.startOf('day'), startDate: dates[0], endDate: dates[dates.length - 1], dates }
  }, [refDateStr, view])

  const { getStatusMap } = useCustomFormsStatus()
  const formStatuses = getStatusMap(module?.data?.custom_form_id || null)

  const { jobRolesMap } = useJobRoles()
  const propertiesFields = [
    calendarConfig?.requirements_pid,
    calendarConfig?.start_datetime_pid,
    calendarConfig?.job_length_pid,
    calendarConfig?.calendar_description_pid
  ]
    .filter(Boolean)
    .map((pid) => `property__${pid}`)
  const ticketsQuery = useQuery(
    ticketApiV2.list({
      ...ticketsParams,
      fields: [...zodQueryFields(CalendarTicketSchema), ...propertiesFields],
      no_count: true,
      limit: 'None', // as we have a date range, we don't need to limit the results
      timepoint_due__gte: startDate.formatISO(),
      timepoint_due__lt: endDate.formatISO()
    })
  )
  const calendarNotesQuery = useQuery(
    calendarEventsApi.list({
      fields: zodQueryFields(CalendarNoteSchema),
      no_count: true,
      limit: 'None', // as we have a date range, we don't need to limit the results
      start_datetime__gte: startDate.formatISO(),
      start_datetime__lt: endDate.formatISO()
    })
  )

  const handlePanelChange = (date: Dayjs, _: any) => {
    filterService?.setFilter({ ref_date: date.format('YYYY-MM-DD') })
  }

  const eventsByDate: Record<string, CalendarTicket[]> = useMemo(() => {
    return groupBy(
      ticketsQuery.data?.items.map((item) => transformTicket(item, calendarConfig, jobRolesMap, formStatuses)).flat(),
      '_localIsoDate'
    )
  }, [formStatuses, jobRolesMap, calendarConfig, ticketsQuery.data?.items])

  const notesByDate: Record<string, CalendarNote[]> = useMemo(() => {
    return groupBy(
      calendarNotesQuery.data?.items?.map((note) => ({
        ...note,
        _isoStartDate: dayjs.formatISODate(note.start_datetime, false)
      })),
      '_isoStartDate'
    )
  }, [calendarNotesQuery.data?.items])

  return (
    <Spin spinning={ticketsQuery.isFetching}>
      <MobileDayCalendar
        className="sm:hidden"
        dates={dates}
        refDate={refDate}
        eventsByDate={eventsByDate}
        notesByDate={notesByDate}
      />
      <Calendar
        className="hidden sm:block"
        value={refDate}
        onPanelChange={handlePanelChange}
        headerRender={() => null}
        cellRender={(current, info) => {
          const _currentDate = current.format('YYYY-MM-DD')
          const listData = eventsByDate[_currentDate] || []
          const notes = notesByDate[_currentDate] || []
          return <CalendarDayCell date={current} items={listData} notes={notes} />
        }}
      />
    </Spin>
  )
}
