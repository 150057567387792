import { useApp, useModalState, useSession } from '@/hooks'
import { ViewTicketLinkV2 } from '@/modules/ticket/components'
import { refreshQueries } from '@/query'
import { calendarEventsApi } from '@/services/api-service'
import { ActionButton, Button, CopyButton, SaveButton } from '@/ui'
import { cn } from '@/utils'
import { useMutation } from '@tanstack/react-query'
import { Drawer, Form, Input } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useEffect, useMemo, useState } from 'react'
import { CalendarNote, CalendarTicket } from '../../../schemas'

type Props = {
  className?: string
  dates: Dayjs[]
  refDate: Dayjs
  eventsByDate: Record<string, CalendarTicket[]>
  notesByDate: Record<string, CalendarNote[]>
}

export const MobileDayCalendar: FC<Props> = ({ className, dates, refDate, eventsByDate, notesByDate }) => {
  const [_selectedDate, setSelectedDate] = useState<Dayjs | null>(null)
  const selectedDate = useMemo(() => _selectedDate || refDate, [_selectedDate, refDate])
  const [editNote, setEditNote] = useState<CalendarNote | null>(null)
  const { isRender, isOpen, openModal, closeModal, renderModal } = useModalState()

  const addEditNote = (note: CalendarNote | null) => {
    setEditNote(note)
    openModal()
  }

  const closeNoteModal = () => {
    setEditNote(null)
    closeModal()
  }

  const isSameDates = (date1: Dayjs, date2: Dayjs) => date1.formatISODate(false) === date2.formatISODate(false)

  const dayEvents = useMemo(
    () => eventsByDate[dayjs.formatISODate(selectedDate, false) || ''] || [],
    [selectedDate, eventsByDate]
  )
  const dayNotes = useMemo(
    () => notesByDate[dayjs.formatISODate(selectedDate, false) || ''] || [],
    [selectedDate, notesByDate]
  )

  useEffect(() => {
    return setSelectedDate(null)
  }, [setSelectedDate, refDate])

  return (
    <div className={className}>
      <div className="mb-16">
        <div className="flex flex-row justify-between gap-10">
          {dates.map((date) => (
            <div
              key={date.day()}
              className={cn('flex flex-col items-end w-full rounded-sm hover:cursor-pointer hover:bg-primary-100', {
                'bg-primary-100': isSameDates(date, selectedDate)
              })}
              onClick={() => setSelectedDate(date)}
            >
              <div className="font-bold">{date.format('dd')}</div>
              <div
                className={cn('my-4 mx-0 h-[1px] w-full bg-gray rounded', {
                  'bg-primary': isSameDates(date, selectedDate)
                })}
              />
              <div className={cn('text-gray-400', { 'text-primary': isSameDates(date, selectedDate) })}>
                {date.format('DD')}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-16">
        <div className="w-full bg-gray-100 px-4">Tickets</div>
        {dayEvents.length ? (
          dayEvents.map((ticketEv) => (
            <div key={ticketEv.id} className="bg-primary-200 rounded-sm p-6 my-6">
              <div className="flex flex-row justify-between text-primary">
                <div>{dayjs.formatLocalTime(ticketEv._localIsoDateTime)}</div>
                <ViewTicketLinkV2 ticketId={ticketEv.id} formId={ticketEv.custom_form_id}>
                  # {ticketEv.name}
                </ViewTicketLinkV2>
              </div>
              <span className="text-primary-800">{ticketEv._jobText}</span>
            </div>
          ))
        ) : (
          <div className="flex flex-row justify-center">No Tickets</div>
        )}
      </div>

      <div>
        <div className="w-full bg-gray-100 px-4">Notes</div>
        {dayNotes.length ? (
          dayNotes.map((note) => (
            <div key={note.id} className="bg-emerald-200 rounded-sm p-6 my-6 flex flex-row items-start justify-between">
              <div className="text-wrap">{note.title}</div>
              <Button
                type="text"
                size="small"
                onClick={() => addEditNote(note)}
                onFocus={renderModal}
                onMouseEnter={renderModal}
              >
                Expand
              </Button>
            </div>
          ))
        ) : (
          <div className="flex flex-row justify-center">No Notes</div>
        )}
      </div>

      <Button
        className="fixed bottom-24 right-12"
        size="small"
        primary
        shape="round"
        iconName="fa:comment-plus"
        onClick={() => addEditNote(null)}
        onFocus={renderModal}
        onMouseEnter={renderModal}
      >
        Add Note
      </Button>
      {isRender ? (
        <Drawer
          placement="bottom"
          closable={false}
          open={isOpen}
          className="p-0"
          onClose={closeNoteModal}
          classNames={{ body: 'p-10' }}
          styles={{ body: { padding: '14px' } }}
        >
          {isOpen ? <AddEditNoteForm note={editNote} date={selectedDate} closeModal={closeNoteModal} /> : null}
        </Drawer>
      ) : null}
    </div>
  )
}

type AddEditNoteFormType = {
  note: CalendarNote | null
  date: Dayjs
  closeModal: () => void
}

const AddEditNoteForm = ({ note, date, closeModal }: AddEditNoteFormType) => {
  const { notification } = useApp()
  const { company } = useSession()
  const [form] = Form.useForm()
  const [editMode, setEditMode] = useState(!note?.id)

  const [title, setTitle] = useState<string | null>(note?.title || null)
  const [description, setDescription] = useState<string | null>(note?.description || null)

  const saveMutation = useMutation(note?.id ? calendarEventsApi.patch() : calendarEventsApi.create())
  const deleteMutation = useMutation(calendarEventsApi.delete())

  const _onSave = async () => {
    try {
      let data = await form.validateFields()

      if (!data.id) {
        data = {
          ...data,
          company_id: company.id,
          type: 'N',
          start_datetime: date,
          end_datetime: date
        }
      }
      await saveMutation.mutateAsync(data)
      notification.success({ message: 'Note Saved' })
      refreshQueries(['v2/calendar_events'])
      closeModal()
    } catch (e) {
      notification.error({ message: 'Failed to Save Note' })
    }
  }

  const onDelete = async () => {
    try {
      await deleteMutation.mutateAsync({ id: note?.id })
      notification.success({ message: 'Note deleted' })
      closeModal()
    } catch (e) {
      notification.error({ message: 'Failed to Delete Note' })
    }
  }

  return (
    <div>
      <div className="flex flex-row items-center gap-10 mb-12">
        <Button iconName="fa:arrow-left" type="text" onClick={closeModal} />
        <h4 className="mb-0">{note?.id ? 'Note' : 'Add Note'}</h4>
        <CopyButton copyText={`${title}\n\n${description}`} size="small" />
        <div className="flex-1"></div>
        {editMode ? (
          <SaveButton onSave={_onSave} size="small" showReturn={false} />
        ) : (
          <Button iconName="fa:edit" type="text" onClick={() => setEditMode(true)} />
        )}
      </div>

      <Form form={form} initialValues={note || {}}>
        {editMode ? (
          <>
            <Form.Item name="id" hidden />
            <Form.Item name="title" label="Title">
              <Input onChange={(e) => setTitle(e.target.value)} />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <Input.TextArea autoSize={{ minRows: 2 }} onChange={(e) => setDescription(e.target.value)} />
            </Form.Item>
          </>
        ) : (
          <>
            <p className="font-bold">{note?.title}</p>
            <p className="text-wrap whitespace-pre-wrap">{note?.description}</p>
          </>
        )}

        {note?.id && !editMode ? (
          <ActionButton
            danger
            iconName="fa:trash"
            shape="round"
            type="dashed"
            className="fixed bottom-24 right-12"
            confirm={{
              title: 'Delete this Note?',
              onConfirm: onDelete,
              className: 'fixed bottom-32 right-24',
              placement: 'topRight'
            }}
          >
            Delete
          </ActionButton>
        ) : null}
      </Form>
    </div>
  )
}
